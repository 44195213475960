import React from 'react';
import './phone-number.component.scss';
import PhoneInput, { Country } from 'react-phone-number-input';
import uniqueId from 'lodash.uniqueid';
import { Label } from '../elements/label.component';
import flags from 'react-phone-number-input/flags';
import { useSelector } from 'react-redux';
import {
  useCallback,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';

interface Props {
  phoneValue: string;
  onPhoneChange: (value: string) => any;
  withLabels?: boolean;
  required?: boolean;
  id?: string;
  setFlagCountryCode?: Dispatch<SetStateAction<string>>;
  [key: string]: any;
  disabled?: boolean;
}

const countryCodes = ['AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX',
  'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT',
  'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV',
  'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI',
  'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ',
  'GR', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR',
  'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA',
  'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK',
  'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE',
  'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM',
  'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH',
  'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TG',
  'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA',
  'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];

export const PhoneNumberInput = ({ phoneValue, onPhoneChange, withLabels, required, id, setFlagCountryCode, disabled,  ...props }: Props) => {
  const inputId = id ? id : uniqueId('phone-input-');
  const [isValidState, setIsValidState] = useState(true);

  const currentUser = useSelector((state: NavCarrierState) => state.auth.user);
  const currentUserCountry = currentUser?.properties?.country;
  const userCountryCode = useMemo(
    () => countryCodes.includes(currentUserCountry)
      ? currentUserCountry as Country
      : 'US', [currentUserCountry]);

  // set initial country code if prop is passed
  useEffect(() => {
    setFlagCountryCode && setFlagCountryCode(userCountryCode);
  }, []);

  const onCountryChange = useCallback((value: Country) => {
    // If you pass incorrect number initially, library fails to determine country and starts throwing errors.
    // To avoid that I clear form value when user starts editing invalid input.
    setIsValidState(Boolean(value));
    // if prop is passed then run the function to set the flags country code in parent component on change
    setFlagCountryCode && setFlagCountryCode(value);
  }, [setIsValidState]);

  const onNumberChange = useCallback((value: string) => {
    onPhoneChange(value);
  }, [onPhoneChange]);

  const onFocus = useCallback((event: any) => {
    if (!isValidState) {
      onPhoneChange('');
    }
  }, [onPhoneChange, isValidState]);

  return (
    <React.Fragment>
      {withLabels && <Label required={required} htmlFor={inputId} resource="PHONE_NUMBER" />}
      <PhoneInput
        id={inputId}
        defaultCountry={userCountryCode}
        international
        countryCallingCodeEditable={false}
        value={phoneValue}
        onChange={onNumberChange}
        onCountryChange={onCountryChange}
        limitMaxLength
        flags={flags}
        onFocus={onFocus}
        disabled={disabled ?? false}
        {...props} />
    </React.Fragment>
  );
};
