export const RegistrationFormContainer = {
	maxWidth: '868px',
	margin: '32px auto',
};

export const Heading = {
	paddingBottom: '24px',
};

export const Subtitle = {
	paddingBottom: '12px',
};

export const RadioButton = {
	border: '1px solid #AAAEB0',
	borderRadius: '8px',
	padding: '16px 16px 16px 0px',
	marginTop: '12px',
	width: '100%',
	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.08)',
	},
};

export const SelectedRadioButton = {
	...RadioButton,
	background: 'rgba(0, 113, 199, 0.08)',
	border: '2px solid #0071C7',
	color: '#0071C7 !important',
};

export const ErroredRadioButton = {
	...RadioButton,
	border: '2px solid #CD0202',
};

export const SelectedRadioHeader = {
	color: '#0071C7',
};

export const RadioInput = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
};

export const FormContainer = {
	background: '#FFFFFF',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'column',
	borderRadius: '8px',
	padding: '24px',
	margin: '16px 0px 32px 0px',
};

export const InputGrid = {
	padding: '16px 0px',
};

export const HiddenGridItem = {
	visibility: { sm: 'hidden' },
	display: {
		xs: 'none',
		sm: 'block',
	},
};

export const FormButton = {
	height: '53px',
	width: '50%',
	border: '1px solid #AAAEB0',
};

export const SelectedButton = {
	...FormButton,
	background: 'rgba(0, 113, 199, 0.08)',
	border: '2px solid #0071C7 !important',
};

export const Checkbox = {
	'& .PrivateSwitchBase-input': {
		position: 'absolute',
	},
};

export const PromotionalMessagingConsent = {
	alignItems: 'flex-start',
	padding: '16px 0px',
};

export const Caption = {
	fontFamily: 'Open Sans',
	fontSize: 12,
	fontWeight: 400,
	lineHeight: '16px',
	letterSpacing: '0.4px',
	textAlign: 'left',
};

export const AccountInfoContainer = {
	padding: '16px 0px 16px 0px',
};

export const SubmissionButtonContainer = {
	display: 'flex',
	justifyContent: 'flex-end',
};

export const SubmissionButton = {
	width: 'auto',
};

export const PositionErrorBox = {
	height: '32px',
	display: 'flex',
	alignItems: 'center',
};

export const ErrorMessageStyles = {
	margin: '4px 14px 0px 0px',
	color: '#8E0000',
	display: 'flex',
	alignItems: 'center',
};

export const ErrorIconStyles = {
	fontSize: '14px',
	marginRight: '4px',
};

export const InputWithNoLabelPadding = {
	paddingTop: { sm: '26px' },
};
